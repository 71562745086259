import $cookies from 'vue-cookies';
import store from '@/store';
import * as roleService from '@/utils/role';

export function authMiddleware(to, from, next) {
    // Perform authentication check or any other logic
    const isAuthenticated = $cookies?.isKey('user_token');

    const userAboutActive = $cookies?.isKey('user');
    store.commit('setGlobalSearch', '');
    if (isAuthenticated && userAboutActive) {
        // User is authenticated, proceed to the next route
        return next();
    } else {
        // User is not authenticated, redirect to login or another route
        return next('/auth/login');
    }
}

export function isAdmin(to, from, next) {
    if (roleService.isAdmin()) {
        return next();
    } else {
        return next('/');
    }
}

export function checkRoleMiddleware(to, from, next, v) {
    if (to.meta.can()) {
        return next();
    } else {
        return next('/');
    }
}
