<template>
    <component v-bind:is="layout">
        <slot />
    </component>
</template>
<script setup>
    import { computed } from 'vue';

    import { useMeta } from '@/composables/use-meta';
    import { useRoute } from 'vue-router';
    useMeta({ title: 'Admin' });
    const route = useRoute();

    // layouts
    import app from '@/layouts/app-layout.vue';
    import auth from '@/layouts/auth-layout.vue';

    const layouts = {
        app,
        auth,
    };

    const layout = computed(() => {
        const metaLayout = route?.name ? route?.meta?.layout || 'app' : 'app';
        return layouts[metaLayout];
    });
</script>
