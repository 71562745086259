import $cookies from 'vue-cookies';
import { ROLE_ADMIN, ROLE_LABORATORY, ROLE_REGISTRATION, ROLE_UZI } from '@/enums/role';
import { isUndefinedOrEmptyStringOrNull } from './reuseble';
export function isAdmin() {
    return $cookies.get('user')?.role == ROLE_ADMIN;
}
export function isAdminRegistration() {
    return $cookies.get('user')?.role == ROLE_REGISTRATION;
}

export function isAdminUzi() {
    return $cookies.get('user')?.role == ROLE_UZI;
}

export function isAdminLaboratory() {
    return $cookies.get('user')?.role == ROLE_LABORATORY;
}

export function getRoleText(role) {
    if (isUndefinedOrEmptyStringOrNull(role)) {
        return null;
    }
    if (isNaN(role)) {
        return role;
    }
    if (role == ROLE_ADMIN) {
        return 'admin';
    } else {
        return role;
    }
}
