export function reformatNumber(n) {
    // format number 1,234,567 to 1234567

    const res = new String(n).split(' ').join('');

    return res;
}

export function formatNumber(n) {
    // format number 1000000 to 1 234 567
    return `${n}`.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatCurrency(input, blur) {
    let inputVal = input.value;

    if (inputVal === '') {
        return;
    }

    let originalLen = inputVal.length;

    let caretPos = input.selectionStart;
    let decimalPos = inputVal.indexOf('.');

    if (decimalPos >= 0) {
        let leftSide = inputVal.substring(0, decimalPos);
        let rightSide = inputVal.substring(decimalPos);

        leftSide = formatNumber(leftSide);
        rightSide = formatNumber(rightSide);

        if (blur === 'blur') {
            rightSide += '00';
        }

        rightSide = rightSide.substring(0, 2);
        inputVal = leftSide + '.' + rightSide;
    } else {
        inputVal = formatNumber(inputVal);

        if (blur === 'blur') {
            inputVal += '.00';
        }
    }

    const event = new CustomEvent('input');

    let updatedLen = inputVal.length;

    caretPos += updatedLen - originalLen;

    input.setSelectionRange(caretPos, caretPos);
    input.value = inputVal;
    input.dispatchEvent(event);
}

export function initCustomDirectives(app) {
    const customDirective = {
        beforeMount(el) {
            el.addEventListener('keyup', async () => {
                formatCurrency(el);
            });
            el.addEventListener('blur', async () => {
                formatCurrency(el, 'blur');
            });
        },
        unmounted(el) {
            // Remove event listener
            el.removeEventListener('keyup', async () => {
                formatCurrency(el);
            });
            el.removeEventListener('blur', async () => {
                formatCurrency(el, 'blur');
            });
        },
    };

    app.directive('currency-input', customDirective);
}
